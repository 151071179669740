import React from "react"
import Footer from "../components/footer"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

export default function Layout({ children }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `
  )
  
  const { title, description } = site.siteMetadata;

  return (
    <div>
      <Helmet 
        title={title}
        // titleTemplate={`%s | ${description}`}
        meta={[
          {
            name: `description`,
            content: description,
          }
        ]}
      >
      </Helmet>
      {/* <Header></Header> */}
      {children}
      <Footer></Footer>
    </div>
  );
}

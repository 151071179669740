import React from "react"
import { useStaticQuery, graphql } from "gatsby"
// import { css } from "@emotion/core"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGithubSquare } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faInstagramSquare } from '@fortawesome/free-brands-svg-icons'
import footerStyles from './footer.module.scss'
import Container from './container'
import { OutboundLink } from "gatsby-plugin-google-gtag"

const ListIcon = (props) => {
  const iconStyle = [footerStyles.listItem, footerStyles.listItem__icons].join(' ');

  return (
    <li className={iconStyle}>
      <OutboundLink
        href={props.url} 
        target="_blank" 
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon className="icons logos" icon={props.icon} size="lg" />
      </OutboundLink>
    </li>
  );
}

export default function Footer() {
  const data = useStaticQuery(graphql`
    query SocialLinksQuery {
      site {
        siteMetadata {
          socials { linkedin, github, instagram }
        }
      }
    }
  `);
  
  const {socials} = data.site.siteMetadata;

  return (
    <section id={footerStyles.footerSection} >
      <Container>

      <div className={footerStyles.footer}>
        <div className="copyright">
          <span>© Erik Tran 2020 | Made with <OutboundLink href="https://www.gatsbyjs.com/docs/quick-start" target="_blank" rel="noopener noreferrer">gatsby.js</OutboundLink> using template <OutboundLink href="https://github.com/gatsbyjs/gatsby-starter-hello-world" target="_blank" rel="noopener noreferrer">hello-world</OutboundLink></span>
        </div>
        <div className={footerStyles.socials}>
          <ul className={footerStyles.list}>
            <ListIcon 
              url={socials.github} 
              icon={faGithubSquare} 
              ></ListIcon>
            <ListIcon 
              url={socials.linkedin} 
              icon={faLinkedin} 
              ></ListIcon>
            <ListIcon 
              url={socials.instagram} 
              icon={faInstagramSquare} 
              ></ListIcon>
          </ul>
        </div>
      </div>

      </Container>
    </section>
  )
}
